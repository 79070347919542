export class ReservationDTO {
  id: number;
  serviceId: number;
  serviceName: string;
  centerId: number;
  centerName: string;
  userId?: number;
  startTime: string;
  endTime: string;
  type: number;
  name: string;
  surname: string;
  phone: string;
  mail: string;
  numberDocumentation: string;
  acceptTractament: Date;
  observations?: string;
  documentType: number;
  startTimeDate: Date;
  endTimeDate: Date;
  codeIdentifier: string;
}
