import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  forwardRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { API_URL, environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';

export enum Type {
  Image,
  File
}

enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2'
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public static readonly Type = Type;
  private static readonly IMAGE_EXTTENSIONS = ['.jpg', '.jpeg', '.png', '.webp']

  private headers: HttpHeaders = new HttpHeaders();

  @Input()
  maxFiles = 1;

  @Input()
  viewOnly = false;

  @Input()
  communityNameInput: string;

  @Input()
  requiredFileType: string;

  @Input()
  events: EventEmitter<string>;

  @Input()
  type: Type = Type.Image;

  @Input()
  category: string;

  @Input()
  fit = 'cover';

  @Input()
  files: UploadedFileModel[] = [];

  @Input()
  apiVersion: ApiVersion | keyof typeof ApiVersion = ApiVersion.v2;

  @Input()
  modelProp: string | null = null;

  @Output()
  filesChange = new EventEmitter<UploadedFileModel[]>();

  @ViewChild('fileUpload') fileUpload: ElementRef;

  public componentUuid: string;

  public Type = Type;

  private eventsSubscription: Subscription;

  dropZone_IsDragOver = false;

  propagateChangeOut = (_: any) => {};

  communityName: string;
  community: CommunityDTO;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private _changesDetected: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private communityService: CommunityService
  ) { }

  writeValue(value: string): void {
    if (value !== undefined) {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach(x => {
            x.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(y => x.path.toLowerCase().includes(y));
          });
          this.files = value;
        } else {
          this.files = [this.mapFromProp(value)];
        }
      } else {
        this.files = [];
      }
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChangeOut = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  propagateChange() {
    this.filesChange.emit(this.files);

    if (this.maxFiles === 1) {

      if (!this.files.length) {
        this.propagateChangeOut(null);
        return;
      }

      this.propagateChangeOut(this.mapToProp(this.files[0]));

    } else {
      this.propagateChangeOut(this.files.map(x => this.mapToProp(x)));
    }
  }

  async ngOnInit() {

    this.componentUuid = (Math.random() * 9999).toString();

    this.community = await firstValueFrom(this.communityService.Get());

    this.communityName = this.community.name;

    if(!this.communityName){
      this.communityName = this.communityNameInput;
    }

    if (this.events) {
      this.eventsSubscription = this.events.subscribe((event) => {
        switch (event) {
          case 'open':
            this.fileUpload.nativeElement.click();
            break;
          case 'reset':
            this.files = [];
            this.files.slice(0 , this.files.length);
            this._changesDetected.detectChanges();
            this.propagateChange();
            (<HTMLInputElement>this.fileUpload.nativeElement).value = '';
        }
      });
    }

    /*if (this._initialFiles) {
      this.files = this._initialFiles.map(x => ({ path: x }) as UploadedFileModel);
    }*/
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  onFileSelected(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.uploadFileOrImage(file);
    }

    (<HTMLInputElement>this.fileUpload.nativeElement).value = '';
  }

  uploadFileOrImage(file: File, replaceFile = null) {

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const townId = this.community.id;

      const model = new UploadedFileModel();

      if (!this.files) { this.files = []; }

      model.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(y => file.name.toLowerCase().includes(y));

      var callback = () => {
        if (model.isImage || this.type === Type.Image) {

          if (this.apiVersion == ApiVersion.v2) {
            this.uploadImageV2(model, townId, formData);
          } else {
            this.uploadImage(model, townId, formData);
          }
        } else if (this.type === Type.File) {
          this.uploadFileV2(model, townId, formData);
        }
      };

      let replaceIndex = replaceFile ? this.files.indexOf(replaceFile) : null;

      if (replaceIndex != null && replaceIndex != -1) {
        Swal.fire({
          reverseButtons: true,
          title: this.translate.instant('AreYouSure'),
          html: this.translate.instant('FILE_UPLOADER.FileWillOverwrite'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ef5350',
          //cancelButtonColor: '#2f4050',
          confirmButtonText: this.translate.instant('Continue'),
          cancelButtonText: this.translate.instant('Cancel'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.files[replaceIndex] = model;
            callback();
          }
        });
      }else{
        this.files.push(model);
        callback();
      }

    }
  }

  private uploadImage(model: UploadedFileModel, communityId: number, formData: FormData) {
    if (model) {
      const upload$ = this.http.post(`${API_URL}/Files/uploadImage?townId=${communityId}&category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / uploadEvent.total);
        } else if (uploadEvent instanceof HttpResponse) {
          model.path = uploadEvent.body;

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  private uploadImageV2(model: UploadedFileModel, communityId: number, formData: FormData) {
    if (model) {
      const upload$ = this.http.post<any>(`${API_URL}/v2/Files/uploadImagePublic?townId=${communityId}&category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / uploadEvent.total);
        } else if (uploadEvent instanceof HttpResponse) {
          model.id = uploadEvent.body.id;
          model.path = uploadEvent.body.path;
          model.checksum = uploadEvent.body.checksum;

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }
  private uploadFileV2(model: UploadedFileModel, communityId: number, formData: FormData) {
    if (model) {
      const upload$ = this.http.post<any>(`${API_URL}/v2/Files/uploadFilePublic?townId=${communityId}&category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events'
      })
        .pipe(
          finalize(() => model.reset()),
        );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / uploadEvent.total);
        } else if (uploadEvent instanceof HttpResponse) {
          model.id = uploadEvent.body.id;
          model.path = uploadEvent.body.path;
          model.originalName = uploadEvent.body.originalName;
          model.checksum = uploadEvent.body.checksum;

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  private uploadFile(model: UploadedFileModel, communityId: number, formData: FormData) {
    if (model) {
      const upload$ = this.http.post<any>(`${API_URL}/Files/uploadFile?townId=${communityId}&category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / uploadEvent.total);
        } else if (uploadEvent instanceof HttpResponse) {
          model.id = uploadEvent.body.id;
          model.path = uploadEvent.body.path;
          model.originalName = uploadEvent.body.originalName;
          model.checksum = uploadEvent.body.checksum;

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  async show(event: any, file: UploadedFileModel) {
    if (file.isImage || this.type === Type.Image) {
      event.currentTarget?.parentElement?.querySelector('img')?.click();
    } else if(this.type === Type.File){
      window.open( environment.storageURL + file.path, '_blank');
    }
  }

  async removeConfirm(event: any, file: UploadedFileModel) {

    event.stopPropagation();

    Swal.fire({
      reverseButtons: true,
      title: this.translate.instant('AreYouSure'),
      html: this.translate.instant('FILE_UPLOADER.FillWillDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      //cancelButtonColor: '#2f4050',
      confirmButtonText: this.translate.instant('FILE_UPLOADER.YesDelete'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.remove(file);
      }
    });
  }

  remove(file: UploadedFileModel) {
      const index = this.files.indexOf(file, 0);
      if (index > -1) {
        this.files.splice(index, 1);
      }


    this.propagateChange();
    //(<HTMLInputElement>this.fileUpload.nativeElement).value = '';
  }

  onClick() {
    this.fileUpload.nativeElement.click();
  }

  dropHandler(ev, replaceFile = null) {
    if (this.viewOnly) {
      return;
    }
    this.setDragover(false, replaceFile);

    // Evitar el comportamiendo por defecto (Evitar que el fichero se abra/ejecute)
    ev.preventDefault();

    if (!this.files) { this.files = []; }

    if (!replaceFile && (this.files.length + this.filesCount(ev)) > this.maxFiles) {
      return;
    }

    if (ev.dataTransfer.items) {
      // Usar la interfaz DataTransferItemList para acceder a el/los archivos)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // Si los elementos arrastrados no son ficheros, rechazarlos
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          this.uploadFileOrImage(file, replaceFile);
        }
      }
    } else {
      // Usar la interfaz DataTransfer para acceder a el/los archivos
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        this.uploadFileOrImage(ev.dataTransfer.files[i], replaceFile);
      }
    }

    // Pasar el evento a removeDragData para limpiar
    this.removeDragData(ev);
  }

  dragEnterHandler(ev, file = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(true, file);
  }

  dragOverHandler(ev, file = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(true, file);

    if (this.filesCount(ev) > this.maxFiles) {
      ev.dataTransfer.dropEffect = 'none';
    }
  }

  dragLeaveHandler(ev, file = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(false, file);
  }

  private setDragover(state: boolean, file = null){
    if(file) {
      file.isDragover = state;
      this.dropZone_IsDragOver = false;
    } else {
      this.dropZone_IsDragOver = state;
    }
  }

  private filesCount(ev: any) {
    if (ev.dataTransfer.items) {
      return ev.dataTransfer.items.length;
    } else {
      return ev.dataTransfer.files.length;
    }
  }

  private removeDragData(ev) {
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      ev.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      ev.dataTransfer.clearData();
    }
  }

  private mapFromProp(prop: any) {
    let model: UploadedFileModel;
    switch (this.modelProp) {
      case 'path':
        model = { path: prop } as UploadedFileModel;
        break
      default:
        model = prop;
    }

    model.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(x => model.path.toLowerCase().includes(x));

    return model;
  }

  private mapToProp(model: UploadedFileModel) {
    switch (this.modelProp) {
      case 'path':
        return model.path;
      default:
        return model;
    }
  }
}


export class UploadedFileModel {
  id: number;
  path: string;
  originalName?: string;
  type?: Type = Type.Image;
  checksum?: string;
  isImage?: boolean;
  uploadProgress?: number;
  dashoffset?: number;
  uploadSub?: Subscription;
  error?: string;
  isDragover?: boolean;

  updateProgress(progress: number) {
    this.uploadProgress = Math.round(100 * progress);

    setTimeout(() => {
      this.dashoffset = ( 1 - progress ) * (2 * (22 / 7) * 40);
    }, progress === 1 ? 200 : 0);
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.updateProgress(0);
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
